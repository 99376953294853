/* eslint-disable prettier/prettier */
/* eslint-disable complexity */
import {dateFormatter, timeFormatter, getUtcOffset} from '@hconnect/uikit'
import {Grid} from '@material-ui/core'
import {Skeleton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

// eslint-disable-next-line import/no-cycle
import {BusinessLineType} from '../../../common/types'
import {Address} from '../../../Molecules/Address'
import MaterialDescription from '../../../Molecules/MaterialDescription'
import {Features, useFeaturesState} from '../../../Organisms/Features'
import {Plant} from '../../../Organisms/Plants/Plants.types'
import {FieldDetails, FieldValue, RequestedDate, TimezoneWarning, PlacedDate} from '../components'
import {QuantityUomToSymbolComponent} from '../Order.components'
import {Order, OrderLineItem, OrderStatus} from '../Order.types'
import {
  getEventLocationOffset,
  getIsAGG,
  getIsCEM
} from '../Order.utils'

import {CanceledBy} from './components/CanceledBy'

type OrderSummaryProps = {
  order: Order
  plant: Plant | undefined
  isPlantsFetching?: boolean
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({order, plant, isPlantsFetching}) => {
  const {getFeature} = useFeaturesState()

  const showState = getFeature('ShowState')
  const showRecipeNumber = getFeature('ShowRecipeNumber')
  const showDeliveryInstructions = order.purchaseOrderType === 'YHUB'
  const deliveryInstructions = order.note?.itemTexts
    .slice(1, 4)
    .filter((value) => value !== null && value !== undefined)
    .join('')

  const userOffset = getUtcOffset(order.shippingDate)

  const {materialDescription, quantity, quantityUom, customerMaterialDescription}: OrderLineItem =
    order?.lineItems[0] || {}
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const isCem = order ? getIsCEM(order) : false
  const isAgg = order ? getIsAGG(order) : false
  const eventLocationOffset = getEventLocationOffset(order)

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.requestedDate')}
            dataTestId="requested-date"
            gutter
          >
            {isCem || isAgg ? (
              <RequestedDate
                t={t}
                order={order}
                language={language}
                userOffset={userOffset}
                eventLocationOffset={eventLocationOffset}
              />
            ) : order?.schedules && order.schedules.length ? (
              <>
                {`${dateFormatter(
                  order?.schedules[0].scheduleStartDate,
                  language,
                  eventLocationOffset
                )} ${timeFormatter(
                  order?.schedules[0].scheduleStartDate,
                  language,
                  eventLocationOffset
                )}`}
                <TimezoneWarning
                  t={t}
                  order={order}
                  userOffset={userOffset}
                  eventLocationOffset={eventLocationOffset}
                />
              </>
            ) : null}
          </FieldValue>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.placedDate')}
            dataTestId="placed-date"
            gutter
            breakable
          >
            <PlacedDate order={order} />
          </FieldValue>
          {order.orderStatus === OrderStatus.Cancelled &&
            (order.orderCancelerName || order.orderCancelerEmail) && (
              <Grid item xs={10}>
                <FieldValue
                  dataTestId="canceler-name"
                  label={t('order.orderDetails.cancelledByLabel')}
                  breakable
                  gutter
                >
                  <CanceledBy
                    canceledByName={order.orderCancelerName}
                    canceledByEmail={order.orderCancelerEmail}
                  />
                </FieldValue>
              </Grid>
            )}
        </Grid>
        <Grid item xs={6}>
          <Features name="RMCTestReports">
            <FieldDetails
              dataTestId="test-booked"
              label={t('order.orderDetails.testBooked')}
              value={t(`order.isTestBooked.${order?.isTestBooked ? 'booked' : 'none'}`)}
              businessLine={order.businessLine}
              showForBusinesslines={[BusinessLineType.RMC]}
              gutter
            />
          </Features>
        </Grid>
      </Grid>
      {order?.shippingAddress ? (
        <FieldValue
          label={t('order.orderDetails.destination')}
          dataTestId="order-destination"
          gutter
        >
          {order.shippingAddress.siteName}
          {order.shippingAddress.street && (
            <Address
              street={order.shippingAddress.street}
              city={order.shippingAddress.city || ''}
              state={order.shippingAddress.state || ''}
              postalCode={order.shippingAddress.postalCode || ''}
              showState={showState}
            />
          )}
        </FieldValue>
      ) : null}
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.productCode')}
            dataTestId="product-code"
            gutter
            breakable
            spaceAfterLabel={order.isCarbonSavingProduct}
          >
            <MaterialDescription
              materialDescription={materialDescription}
              customerMaterialDescription={customerMaterialDescription}
              isCarbonSavingProduct={order.isCarbonSavingProduct}
            />
          </FieldValue>
        </Grid>
        <Grid item xs={6}>
          <FieldValue label={t('order.orderDetails.orderedQty')} dataTestId="ordered-qty" gutter>
            {quantity} <QuantityUomToSymbolComponent uom={quantityUom} />
          </FieldValue>
        </Grid>
      </Grid>
      {showRecipeNumber && order.recipeNumber && (
        <FieldValue label={t('order.orderDetails.recipeNumber')} dataTestId="recipe-number" gutter>
          {order.recipeNumber}
        </FieldValue>
      )}
      {showDeliveryInstructions && (
        <FieldDetails
          label={t('order.orderDetails.deliveryInstructions')}
          dataTestId="delivery-instructions"
          value={deliveryInstructions}
          fullWidth
          gutter
        />
      )}
      {isCem ? (
        <FieldValue dataTestId="plant-details" label={t('order.orderDetails.plantName')} gutter>
          {isPlantsFetching ? <Skeleton width="60%" /> : plant?.plantName || '-'}
          {plant?.street ? (
            <Address
              street={plant.street}
              city={plant.city || ''}
              state={plant.state || ''}
              postalCode={plant.postalCode || ''}
              showState={showState}
            />
          ) : null}
        </FieldValue>
      ) : null}

      {order.isHaveMoreThanOne && order.orderNumbers && (
        <FieldValue dataTestId="order-number" label={t('order.orderDetails.orderNumber')} gutter>
          {order.orderNumbers.join(', ')}
        </FieldValue>
      )}
    </>
  )
}
